<template>
  <el-col
    v-if="esmobil"
    class="pz-large-margin text-lg-center"
  >
      <el-col
          class="row justify-content-center"
          :span="24"
      >
          <pz-button
              v-if="continuar"
              key="send"
              type="primary"
              :button-name="$t('continue')"
              @click="handleSend"
          />
      </el-col>
    <iframe
      id="naat"
      frameborder="0"
      width="100%"
      :height="height"
      class="pz-large-margin"
      :src="frame_route"
      sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts"
      allow="geolocation; microphone; camera; fullscreen"
    />
  </el-col>

  <el-col
    v-else
    class="pz-large-margin text-lg-center"
  >
    <pz-title>
      <span slot="title">
        Escanear Codigo QR para continuar el proceso en su Mobile <br>
      </span>
    </pz-title>
    <el-row :gutter="20">
      <el-col
        :xl="{span: 24}"
        :lg="{span: 24}"
        :md="{span: 24}"
        :sm="{span: 24}"
        :xs="{span: 24}"
      >
        <vue-qrcode
          :value="url"
          :options="{ width: 200 }"
        />
      </el-col>
    </el-row>
  </el-col>
</template>

<script>

import {mapGetters} from "vuex";
import {isEmpty, isMobile} from '@/commons/utils/functions';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import {getDocsSign,createValidationNaat,saveValidationNaat,getCredencialesNaat,saveFaceNaat} from "@/routes/api/views/vales";
import PzButton from "@/commons/forms/Buttons/PzButton";
import {Objeto} from "@/commons/forms/DigitalSings/Fad/EventoNaat";
import {CONFIGURATIONACUANT, CONFIGURATIONFace}
from "@/commons/forms/DigitalSings/Fad2/Configuracion";
import FadSDK from '@/commons/forms/DigitalSings/Fad2/fad-sdk.min.js';


export default {
    name: "PzFadSdk",
    components: {VueQrcode,PzButton},
    props: {
        route: {
            require: true,
            default: null,
            type: [String, URL]
        },
        redirect: {
            require: true,
            default: 'Prestamos',
            type: [String]
        },
    },
    data() {
        return {
            frame_route: this.route,
            tipo: 1,
            entorno:'',
            intentos: 1,
            side: 0,
            imageId: '',
            imageFace: '',
            ocr: '',
            url: window.location.href,
            height: '600px',
            watch: {
                route(value) {
                    this.frame_route = value;
                },
                frame_route(value) {
                    console.log('change form route');
                    console.log(value);
                },

            },
            continuar: false,
            moduleParams : {
                credentials: '',
                configuration: CONFIGURATIONACUANT,
                side: 0, // 0 - front id, 1 - back id
                idData: false, // true - ocr, false - without this data
                idPhoto: false, // true - get imaghen face of id, false - without this data
                imageQuality: 0.5, // quality of image id, range 0 - 1
            },
            CREDENTIALSAcuant:'',
            CREDENTIALSFace:'',
            sharpnessThreshold:'55',
            middleware:false,
            FAD_SDK :'',
            TOKEN:'',
            ENTORNO:''

        }
    },
    computed: {
        ...mapGetters(['alias']),
        esmobil(){
            return isMobile()
        }
    },

    created() {
        console.log('created');
        this.getCredenciales();
    },
    methods: {
        eventos(event) {
            if(event.data)
                switch (event.data.event) {
                    case 'PROCESS_ERROR':
                        console.log('PROCESS_ERROR');
                        console.log(event.data);
                        break;
                    case 'PROCESS_COMPLETED':
                        console.log("Process completed");
                        var result = event.data.data;

                        if(this.tipo==1){
                            this.crearValidacion({
                                'imageFront':result.id.front.image.data,
                                'imageBack': result.id.back.image.data,
                                'ocr': result.idData.ocr,
                                'foto': result.idPhoto,
                                'alerts': result.idData.alerts
                            });

                        }else if(this.tipo==2){
                            this.saveFace({
                                'imgLowQuality':result.lowQualityAuditTrail[0]
                            });
                        }
                        else{
                              this.continuar= true;
                              this.loading = false;
                              this.handleSend();

                        }
                        break;
                    case 'PROCESS_ENDED':
                        if(this.tipo==3) {
                            this.continuar = true;
                            this.loading = false;
                            this.handleSend();
                            console.log('proceso terminado firma');
                        }
                        break;
                    case 'SIGNATURE_COMPLETED':
                        if(this.tipo==3) {
                            this.continuar = true;
                            this.loading = false;
                            this.handleSend();
                            console.log('proceso de firma completo');
                        }
                        break;
                    default:
                        return;

                }
            },
        iniciarFAD(){
            var options = {
                environment: this.ENTORNO=='UAT'?FadSDK.getFadEnvironments().UAT:FadSDK.getFadEnvironments().PROD,
            };
            var TOKEN=  this.TOKEN ;
            this.FAD_SDK = new FadSDK(TOKEN, options);
        },
        async getCredenciales(){
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            await getCredencialesNaat().
            then((res) => {
                 this.CREDENTIALSAcuant = res.data.CREDENTIALSAcuant;
                 this.CREDENTIALSFace = res.data.CREDENTIALSFace;
                 this.moduleParams.credentials = res.data.CREDENTIALSAcuant;
                 this.sharpnessThreshold = res.data.sharpnessThreshold;
                 this.moduleParams.configuration.sharpnessThreshold =  this.sharpnessThreshold;
                 this.middleware =  res.data.middleware;
                 this.TOKEN =  res.data.token;
                 this.ENTORNO =  res.data.tipo;
                this.iniciarFAD();
                window.addEventListener("message", this.eventos, false);
                this.intentos = 1;
                this.initProcess();
            }).
            catch((error) => {
                this.$store.dispatch('app/catchErrors', {_this: this, error: error});
            }).
            finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name});
            });
        },
         initIframe() {
            const iframe = document.getElementById("naat");
            iframe.src = this.frame_route;
        },
        handleSend() {
            console.log('handleSend');
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            this.$router.push({name: 'FinFirma'});
            this.$store.dispatch('loader/down', {trigger: this.$options.name});
        },
        handleError(){
            if(this.intentos<3) {
                this.iniciarFAD();
                this.$store.dispatch('app/setMessage', 'Nuevo intento para la captura de información');
                this.intentos =  this.intentos +1;
                if(this.tipo==1)
                    this.initProcess();
                else if(this.tipo==2)
                    this.initModuleFacetec();
                else this.initModuleFirma();
            }else{

                this.handleRechazo();
            }
        },
        handleRechazo(comp=0) {
            console.log('handleRechazo');

            this.FAD_SDK.end();
            let motivo ='';
            if(this.tipo==1)
                motivo = this.$t('motivo_rechazo.acuant');
            else if(this.tipo==2)
                motivo= this.$t('motivo_rechazo.facetec');
            else
                motivo = this.$t('motivo_rechazo.firma');
            this.tipo=1;
            this.intentos=1;
            this.side=0;
         //   window.removeEventListener("message", this.eventos, false);
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            this.$router.push({name: 'FadRechazo', params: {mensaje: motivo}});
            this.$store.dispatch('loader/down', {trigger: this.$options.name});
        },
        initModule() {
            const iframe = document.getElementById("naat");
            console.log(this.moduleParams)
            iframe.contentWindow.postMessage(
                Objeto("INIT_MODULE",this.moduleParams), iframe.src);

        },
        async initProcess() {
            try {
                const idData = true; // true - ocr, false - without this data
                const idPhoto = true; // true - get imaghen face of id, false - without this data
                // Returns the image of identification (id.image.data) and relevant information (sharpness, glare), documentInstance, idData and idPhoto
                const acuantResponse = await this.FAD_SDK.startAcuant(this.CREDENTIALSAcuant, idData, idPhoto, CONFIGURATIONACUANT);

            } catch (ex) {
                console.log('error acuant');
                console.log(ex);
                this.FAD_SDK.end();
                this.handleError();

            } finally {
                console.log('finally sdk acuant');

            }
        },
        async initModuleFacetec() {
            console.log('initModuleFacetec ');
            const CREDENTIALS = this.CREDENTIALSFace;
            try {
                // Returns the image of identification (id.image.data) and relevant information (sharpness, glare), documentInstance, idData and idPhoto
                const facetecResponse = await this.FAD_SDK.startFacetec(null,CONFIGURATIONFace, CREDENTIALS);
            } catch (ex) {
                console.log('error face');
                console.log(ex);
                this.FAD_SDK.end();
                this.handleError();
            } finally {
                console.log('finally sdk face');
            }
        },

        async initModuleFirma() {
            console.log('initModuleFirma ');
            this.getRoute(3);
            this.tipo=3;

           // const iframe = document.getElementById("naat");
            var ticket = this.frame_route;
            try {
                const facetecResponse = await  this.FAD_SDK.startFadWeb4(ticket);
            } catch (ex) {
                console.log('error firma');
                console.log(ex);
                this.FAD_SDK.end();
                this.handleError();
            } finally {
                console.log('finally sdk firma');
            }
        },

        async getRoute(tipo=2){
             console.log(this.$route.params);
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            await getDocsSign({'loan':this.$route.params.loan,'tipo':tipo}).
            then((res) => {
                if (res.data.valid && !isEmpty(res.data.ruta)) {
                    this.frame_route = res.data.ruta;
                }
            }).
            catch((error) => {
                console.log('error ruta');
                console.log(error);
                this.$store.dispatch('app/catchErrors', {_this: this, error: error});
            }).
            finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name});
            });
        },

        async crearValidacion(result){
            console.log(this.$route.params);
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            await createValidationNaat(this.$route.params.loan,result).
            then((res) => {
                if (!res.data.valid ) {
                    this.$store.dispatch('app/catchErrors', {_this: this, error: 'Problemas registro naat '});
                }else{
                    this.saveValidation();
                }
            }).
            catch((error) => {
                console.log('error ruta');
                console.log(error);
                this.$store.dispatch('app/catchErrors', {_this: this, error: error});
            }).
            finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name});
            });
        },
        async saveValidation(){
            console.log('saveValidationNaat');
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            await saveValidationNaat(this.$route.params.loan).
            then((res) => {
               if (!res.data.valid ) {
                    this.handleRechazo(1);

                }else{
                    this.getRoute();
                    this.tipo=2;
                    this.intentos = 1;
                   this.initModuleFacetec();
                }
            }).
            catch((error) => {
                console.log('error ruta');
                console.log(error);
                this.$store.dispatch('app/catchErrors', {_this: this, error: error});
            }).
            finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name});
            });
        },
        async saveFace(result){
            console.log('saveFace');
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            await saveFaceNaat(this.$route.params.loan,result).
            then((res) => {
                if (!res.data.valid ) {
                    this.handleRechazo(1);
                }else{
                    this.initModuleFirma();
                }
            }).
            catch((error) => {
                console.log('error saveFace');
                console.log(error);
                this.$store.dispatch('app/catchErrors', {_this: this, error: error});
            }).
            finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name});
            });
        }
    },

}
</script>
